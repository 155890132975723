import { useState, useRef } from "react";
import {
  Tr,
  Th,
  Td,
  Select,
  Input,
  Checkbox,
  Button,
  Flex,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useAuth } from "../auth/AuthProvider";
import universities from "../../data/universities";
import DisplayInvoiceModal from "../DisplayInvoiceModal";

function OpenApp({
  id,
  date_applied,
  first_name,
  last_name,
  phone_number,
  mat_number,
}) {
  const toast = useToast();
  const { register, handleSubmit } = useForm();
  const { token } = useAuth();
  const {
    isOpen: isOpenInvoice,
    onOpen: onOpenInvoice,
    onClose: onCloseInvoice,
  } = useDisclosure();
  const [invoiceURL, setInvoiceURL] = useState(null);

  // TODO: this is repeated
  const processFormData = (formData) => {
    for (const key in formData) if (formData[key] === "") formData[key] = null;
    return formData;
  };

  const resolveApp = async (formData, approve) => {
    formData = processFormData(formData);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/applications/${id}`,
        {
          ...formData,
          approve,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast({
          title: `Application successfully ${
            approve ? "approved." : "denied."
          }`,
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toast({
          title: "Failed to update application",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error updating application: ", err);
      });
  };

  const fetchInvoiceURL = async (e) => {
    const targetTagName = e.target.tagName.toLowerCase();
    if (["input", "select", "button"].includes(targetTagName)) {
      return;
    }
    
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/display_invoices/${id}/file-url`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInvoiceURL(response.data.file_url);
      onOpenInvoice();
    } catch (error) {
      toast({
        title: "Failed to fetch invoice",
        position: "top",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error fetching invoice: ", error);
    }
  };

  return (
    <>
      <Tr onClick={fetchInvoiceURL}>
        <Td>{date_applied?.split("T")[0]}</Td>
        <Td>{first_name + " " + last_name}</Td>
        <Td>{mat_number}</Td>
        <Td>{phone_number}</Td>
        <Td>
          <Select {...register("university_id")}>
            {Object.keys(universities).map((key) => (
              <option key={key} value={key}>
                {universities[key]}
              </option>
            ))}
          </Select>
        </Td>
        <Td>
          <Input {...register("start_date")} type="date" />
        </Td>
        <Td>
          <Input {...register("tuition_amount")} placeholder="Tuition Amount" />
        </Td>
        <Td>
          <Input {...register("arrears_amount")} placeholder="Arrears Amount" />
        </Td>
        <Td>
          <Flex gridGap={2}>
            <Button onClick={handleSubmit((data) => resolveApp(data, true))}>
              Approve
            </Button>
            <Button
              onClick={handleSubmit((data) => resolveApp(data, false))}
              colorScheme="red"
            >
              Deny
            </Button>
          </Flex>
        </Td>
      </Tr>
      <DisplayInvoiceModal
        isOpen={isOpenInvoice}
        onClose={onCloseInvoice}
        imgURL={invoiceURL}
      />
    </>
  );
}

export default OpenApp;
